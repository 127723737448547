import React, { useState, useEffect, useMemo } from 'react';
import { Suspense } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ProductSlider from '../components/ProductSlider';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import Breadcrumb from '../components/Breadcrumb';
import QuantityControl from '../components/QuantityControl';
import AddToCart from '../components/Button';
import PriceFormatter from '../config/formatters/priceFormatter';
import recipeImage from '../images/recipes/recipes_with_ingredient.png';
import { Alert } from '../components/ui/alert';
import AlertModal from '../components/AlertModal';
import {
  useAddWishListMutation,
  useGetProductQuery,
  usePostCartMutation,
} from '../store/slices/api.slice';
import CookieHelper from '../config/utils/cookie.helper';
import { AppKeys } from '../config/utils/constants';

const Product = () => {
  const { productSlug } = useParams<any>();
  const navigate = useNavigate();
  // fetch product details
  // const [productDetails, setProductDetails] = useState<any>({});
  const [counts, setCounts] = useState(1);
  const [showAlert, setShowAlert] = useState({ show: false, msg: '' });
  const {
    data: product,
    isLoading,
    isSuccess,
  } = useGetProductQuery(`${productSlug}`);
  const [postCart, { isLoading: loadingCart }] = usePostCartMutation();
  const [addWishList, { isLoading: loadingItem }] = useAddWishListMutation();

  
  const productDetails: any = useMemo(() => {
    if (isLoading || isSuccess == false) {
      return {};
    } else {
      console.log(product, 'l');
      
      return product.data;
    }
  }, [isLoading, productSlug]);

  const saveItems = async (sku: string) => {
    try {
      const responseData = await addWishList({ sku }).unwrap();

      if (responseData.status === true) {
        setShowAlert({ show: true, msg: responseData.message });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
      } else if (
        responseData.message === 'Error occurred, validation token not found'
      ) {
        navigate('/login');
      } else {
        setShowAlert({ show: true, msg: responseData.message });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
      }
    } catch (error: any) {
        const { message } = error.data
        if(message == 'Error occurred, validation token not found'){
          navigate('/login')
        } else {
          setShowAlert({ show: true, msg: 'Network Error, Check your Internet' });
          setTimeout(
            () => setShowAlert((prevState) => ({ ...prevState, show: false })),
            4000
          );
        }
    }
  };

  const addToCart = async (sku: string, quantity: number, price: any, name: string) => {
    let catalogue = CookieHelper.get(AppKeys.CART) || [];
  let existingItem = catalogue.find((item: any) => item.sku === sku);
  const body = {
    catalogue: [
      {
        sku: sku,
        quantity: JSON.stringify(quantity),
      }
    ]
  }
  if (existingItem) {
    existingItem.quantity = +existingItem.quantity + +quantity;  
  } else {
  catalogue.push({
    sku: sku,
    quantity: +quantity,
    price: price,
    name: name
  })
}
    try {
      const responseData = await postCart(body).unwrap();

      if (responseData.status === true) {
        navigate('/cart');
      } else if (
        responseData.message === 'Error occurred, validation token not found'
      ) {
        CookieHelper.set(AppKeys.CART, 
          catalogue, AppKeys.COOKIE_EXPIRY_PERIOD);
      } else {
        setShowAlert({ show: true, msg: responseData.message });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
      }
    }catch (error: any) {
      const { message } = error.data
      if(message == 'Error occurred, validation token not found'){
        CookieHelper.set(AppKeys.CART, 
          catalogue, AppKeys.COOKIE_EXPIRY_PERIOD);
      } else {
        setShowAlert({ show: true, msg: 'Network Error, Check your Internet' });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
      }
    }
  };

  // breadcrumbs
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    {
      label: `${productDetails?.category?.name}` || '',
      to: `/category/${productDetails?.category?.slug}`,
    },
    { label: productSlug ?? '', to: `/${productSlug}` },
  ];

  const handleIncrement = () => {
    setCounts((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    if (counts > 2) {
      setCounts((prevCount) => prevCount - 1);
    } else {
      setCounts(1);
    }
  };

  const buttonItem = {
    btnTxt: 'Add to cart',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 2048 1792'
      >
        <path
          fill='currentColor'
          d='M1920 768q53 0 90.5 37.5T2048 896t-37.5 90.5t-90.5 37.5h-15l-115 662q-8 46-44 76t-82 30H384q-46 0-82-30t-44-76l-115-662h-15q-53 0-90.5-37.5T0 896t37.5-90.5T128 768zM485 1568q26-2 43.5-22.5T544 1499l-32-416q-2-26-22.5-43.5T443 1024t-43.5 22.5T384 1093l32 416q2 25 20.5 42t43.5 17zm411-64v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m384 0v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m352 5l32-416q2-26-15.5-46.5T1605 1024t-46.5 15.5t-22.5 43.5l-32 416q-2 26 15.5 46.5t43.5 22.5h5q25 0 43.5-17t20.5-42M476 292l-93 412H251l101-441q19-88 89-143.5T601 64h167q0-26 19-45t45-19h384q26 0 45 19t19 45h167q90 0 160 55.5t89 143.5l101 441h-132l-93-412q-11-44-45.5-72t-79.5-28h-167q0 26-19 45t-45 19H832q-26 0-45-19t-19-45H601q-45 0-79.5 28T476 292'
        />
      </svg>
    ),
  };

  return (
    <div className='md:mb-24'>
      <MaxWidthWrapper className='pb-10 lg:bg-neutral-100'>
        <Breadcrumb items={breadcrumbItems} />
        <div className='flex flex-col items-stretch justify-between !mt-0 md:pt-8 lg:flex-row'>
          <ProductSlider productImage={productDetails?.image} isSuccess={isSuccess} />
          <div className='lg:w-auto xl:w-[30%] xl:ms-4 mt-2'>
            <div className='flex items-center justify-between lg:pe-8'>
              <span className='category-btn'>
                {productDetails?.category ? productDetails?.category?.name : ''}
              </span>
            </div>
            <div className='flex gap-x-4 gap-y-3 lg:flex-col lg:items-start items-center mt-1.5 text-lg font-medium text-gray-900 capitalize'>
              <p className='lg:w-[80%] xl:w-full'>{productDetails?.name}</p>
              <p className='hidden mt-2 space-x-2 text-xs font-medium text-gray-800 lg:block'>
                <span className='px-1.5 py-[2px] rounded-full !lowercase'>
                  {productDetails ? productDetails?.weight : ''}
                  {productDetails ? productDetails?.measurement_unit : ''}
                </span>
              </p>
            </div>

            <div className='flex items-center justify-between mb-1 lg:hidden'>
              <p className='flex items-center gap-x-3'>
                <span className='space-x-2 text-2xl font-bold text-gray-800'>
                  <span>₦</span>
                  <span>
                    {productDetails?.category
                      ? PriceFormatter(productDetails?.active_price, false)
                      : ''}
                  </span>
                </span>
              </p>
            </div>

            <p className='w-fit lg:px-2 rounded-full flex items-center mt-3 md:mt-0 md:mb-3.5 lg:mb-0 xl:mb-1 py-1 text-xs font-medium text-gray-700 bg-tkzBg'>
              <span>Market rate is 5% more </span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='pt-[3px]'
                width='20'
                height='20'
                viewBox='0 0 20 20'
              >
                <path
                  fill='currentColor'
                  d='M.7 10.4A.7.7 0 0 1 .7 9h18.606a.7.7 0 0 1 0 1.4z'
                />
              </svg>
              <span className='text-xs font-medium text-green-600'>
                saving ₦20
              </span>
            </p>
            <p className='mt-2 xl:mt-5 flex items-center gap-x-1.5 text-xs font-medium text-gray-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='1.5em'
                height='1.5em'
                viewBox='0 0 24 24'
              >
                <path
                  fill='currentColor'
                  d='M3 13.5L2.25 12H7.5l-.6-1.5H2L1.25 9h7.8l-.6-1.5H1.11L.25 6H4a2 2 0 0 1 2-2h12v4h3l3 4v5h-2a3 3 0 0 1-3 3a3 3 0 0 1-3-3h-4a3 3 0 0 1-3 3a3 3 0 0 1-3-3H4v-3.5zm16 5a1.5 1.5 0 0 0 1.5-1.5a1.5 1.5 0 0 0-1.5-1.5a1.5 1.5 0 0 0-1.5 1.5a1.5 1.5 0 0 0 1.5 1.5m1.5-9H18V12h4.46zM9 18.5a1.5 1.5 0 0 0 1.5-1.5A1.5 1.5 0 0 0 9 15.5A1.5 1.5 0 0 0 7.5 17A1.5 1.5 0 0 0 9 18.5'
                />
              </svg>
              Estimated between Tue, Dec 05 and Fri, Dec 08
            </p>
            {/* <p className='mt-6 mb-2 space-x-2 text-xs font-medium text-gray-800 lg:hidden'>
              <span className='px-1.5 py-[2px] border-2 border-gray-800 rounded-full'>
                {productDetails ? productDetails?.weight : ''}
                {productDetails ? productDetails?.measurement_unit : ''}
              </span>
            </p> */}
          </div>
          <div className='xl:w-[25%] lg:max-w-[25%]'>
            <div className='mt-6 lg:mt-4 lg:flex lg:flex-col lg:w-full xl:gap-x-4 rounded-3xl lg:bg-tkzBg lg:p-4 lg:shadow lg:drop-shadow'>
              <div className='hidden mb-4 lg:block'>
                {productDetails?.stock > 0 ? (
                  ''
                ) : (
                  <p className='py-1 text-xs font-bold text-red-500 '>
                    Out of stock
                  </p>
                )}
                <p className='flex items-center gap-x-3'>
                  <span className='space-x-2 text-2xl font-bold text-gray-800'>
                    <span>₦</span>
                    <span>
                      {productDetails?.category
                        ? PriceFormatter(productDetails?.active_price, false)
                        : ''}
                    </span>
                  </span>
                  <span className='text-sm font-medium line-through text-neutral-600'>
                    {productDetails?.category ? (
                      <>
                        {productDetails?.active_price ===
                        productDetails?.price ? (
                          ''
                        ) : (
                          <>₦ {PriceFormatter(productDetails?.price, false)}</>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                </p>
                <p className='text-xs text-gray-500'>Price per item</p>
              </div>
              <QuantityControl
                className='mb-4'
                quantity={counts}
                onIncrement={() => handleIncrement()}
                onDecrement={() => handleDecrement()}
              />
              <AddToCart
                item={buttonItem}
                onClick={() => {
                  productDetails.stock > 0
                    ? addToCart(productDetails?.sku, counts, productDetails?.price, productDetails?.name)
                    : '';
                }}
              />
            </div>
            <div
              onClick={() => saveItems(productDetails?.sku)}
              className={
                'group bg-neutral-100 lg:bg-tkzBg mt-3 w-full px-3 py-2 flex justify-between items-center hover:bg-green-200 hover:cursor-pointer rounded-full'
              }
            >
              <p className='text-xs font-medium text-gray-700 group-hover:text-red-500'>
                Save item
              </p>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 256 256'
                className='text-gray-600 group-hover:text-red-500'
              >
                <path
                  fill='currentColor'
                  d='M240 94c0 70-103.79 126.66-108.21 129a8 8 0 0 1-7.58 0C119.79 220.66 16 164 16 94a62.07 62.07 0 0 1 62-62c20.65 0 38.73 8.88 50 23.89C139.27 40.88 157.35 32 178 32a62.07 62.07 0 0 1 62 62'
                />
              </svg>
            </div>
          </div>
        </div>
      </MaxWidthWrapper>
      <MaxWidthWrapper className='space-y-6 xl:pt-6 lg:space-y-12 lg:border-t border-neutral-300'>
        <section>
          <h3 className='section-heading-2 text-start md:pt-[2rem]'>
            Description
          </h3>
          <p className='w-full lg:w-[50%] desc-txt'>
            {productDetails?.description !== ''
              ? productDetails?.description
              : 'No description for this Product'}
          </p>
        </section>

        {/* <section className='pb-24'> */}
          {/* <h3 className='section-heading-2 text-start w-[50%]'>
            Explore Culinary Delights with{' '}
            {productDetails?.name !== '' ? productDetails?.name : ''}
          </h3> */}
          {/* <div className='flex flex-col justify-between mt-1 lg:flex-row gap-x-14'>
            <p className='w-full lg:w-[50%] desc-txt'>
              {productDetails?.content !==  '' ? productDetails?.content : ''}

              Our culinary experts recommend that this pure and natural
              groundnut oil is the perfect match for a variety of dishes.
              Looking for other food pairings? Explore other recipes. Our
              culinary experts recommend that this pure and natural groundnut
              oil is the perfect match for a variety of dishes. Looking for
              other food pairings?
              <Link
                to='/'
                className='text-xs text-green-600 ms-2 lg:text-sm hover:text-green-700'
              >
                Explore similar recipes.
              </Link>
            </p>
            <div className='w-full px-4 lg:px-0 mt-6 lg:mt-0 lg:w-[40%] flex flex-col items-center'>
              <img
                src={productDetails?.image || ''}
                alt={productDetails?.title}
                width={1000}
                height={1000}
                className='object-cover w-full rounded-xl'
              />
            </div>
          </div> */}
        {/* </section> */}
        {showAlert.show && (
          <AlertModal
            msg={showAlert.msg}
            click={() =>
              setShowAlert((prevState) => ({ ...prevState, show: false }))
            }
          />
        )}
        <Suspense>
          {/* <FarmFindsCard categorySlug={productDetails?.category?.slug} /> */}
        </Suspense>
      </MaxWidthWrapper>
    </div>
  );
};

export default Product;
