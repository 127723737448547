import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import blackLogo from '../../images/logos/black_logo.svg';
import Input, { ErrorInput } from '../../components/Input';
import { useForm } from 'react-hook-form';
import Loading from '../../components/Loading';
import AuthService from '../../config/services/auth.service';
import AlertModal from '../../components/AlertModal';

const Signup = () => {
  const [visibleText, setVisibleText] = useState('password');
  const [passwordIcon, setPasswordIcon] = useState('fa-eye');
  const [showAlert, setShowAlert] = useState({ show: false, msg: '' });
  const [passwordStrength, setPasswordStrength] = useState({
    score: 0,
    feedback: '',
  });
  const [style, setStyle] = useState('hidden');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
    },
  });

  const password = watch('password', '');

  const handlePasswordVisibility = () => {
    if (passwordIcon === 'fa-eye') {
      setPasswordIcon('fa-eye-slash');
      setVisibleText('text');
    } else if (passwordIcon === 'fa-eye-slash') {
      setPasswordIcon('fa-eye');
      setVisibleText('password');
    }
  };

  const checkPasswordStrength = (password: string) => {
    let score = 0;
    let feedback = [];

    if (password.length >= 12) score += 2;
    else if (password.length >= 8) score += 1;
    else feedback.push('Password should be at least 8 characters long');

    if (/[A-Z]/.test(password)) score += 1;
    else feedback.push('Add at least one uppercase letter');

    if (/[a-z]/.test(password)) score += 1;
    else feedback.push('Add at least one lowercase letter');

    if (/\d/.test(password)) score += 1;
    else feedback.push('Add at least one number');

    if (/[^A-Za-z0-9]/.test(password)) score += 1;
    else feedback.push('Add at least one special character');

    const commonPatterns = ['password', '1234', 'qwerty', 'admin', 'welcome'];
    if (
      !commonPatterns.some((pattern) =>
        password.toLowerCase().includes(pattern)
      )
    ) {
      score += 1;
    } else {
      feedback.push('Avoid common words and patterns');
    }

    const result = {
      score,
      feedback: feedback.length > 0 ? feedback.join('. ') : '',
    };

    setPasswordStrength(result);
    return result;
  };

  useEffect(() => {
    if (password) {
      checkPasswordStrength(password);
    }
  }, [password]);

  const onSignup = async (values: { password: string }) => {
    const { score } = checkPasswordStrength(values.password);

    if (score < 5) {
      setShowAlert({
        show: true,
        msg: 'Password is too weak. Please follow the password requirements.',
      });
      setTimeout(
        () => setShowAlert((prev) => ({ ...prev, show: false })),
        4000
      );
      return;
    }

    setLoading(true);
    try {
      const { data: responseData } = await AuthService.signup(values);
      if (responseData.status) {
        setLoading(false);
        setShowAlert({ show: true, msg: responseData.message });
        setTimeout(
          () => setShowAlert((prev) => ({ ...prev, show: false })),
          4000
        );
        navigate('/login');
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setShowAlert({ show: true, msg: error.data.message });
      setTimeout(
        () => setShowAlert((prev) => ({ ...prev, show: false })),
        4000
      );
    }
  };

  return (
    <section className='authPageWrapper'>
      <div
        id='toast-default'
        className={`${style} fixed inset-x-[40%] top-0 items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`}
        role='alert'
      >
        <button
          type='button'
          className='ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700'
          data-dismiss-target='#toast-default'
          aria-label='Close'
        >
          <span className='sr-only'>Close</span>
          <svg
            className='w-3 h-3'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 14 14'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
            />
          </svg>
        </button>
      </div>

      <div className='w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%] flex justify-end'>
        <Link
          to='/'
          className='p-2 border rounded-full w-fit h-fit border-neutral-300 hover:text-white hover:bg-green-700'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
          >
            <path
              fill='currentColor'
              d='M12.269 15.794a.75.75 0 0 1-1.06-.026l-5.002-5.25a.75.75 0 0 1 0-1.035l5.001-5.25a.75.75 0 1 1 1.086 1.034l-4.508 4.734l4.508 4.733a.75.75 0 0 1-.025 1.06'
            />
          </svg>
        </Link>
      </div>

      <div className='w-[80%] md:w-[70%] lg:w-[50%] xl:w-[40%] flex flex-col items-center'>
        <Link to='/' className='py-4'>
          <img
            src={blackLogo}
            alt='Taikez ecommerce logo'
            width={300}
            height={300}
            className='w-[7rem]'
          />
        </Link>

        <h4 className='mt-4 mb-3 text-xl font-bold'>Create an account</h4>
        <p className='font-thin text-center lg:text-lg xl:text-xl'>
          Join thousands of customers who purchase their daily food items on
          TAIKEZ!
        </p>

        <Link
          to='auth-4'
          className='checkout-btn !bg-transparent my-6 py-1 md:!py-1.5 !border-2 !border-gray-800 hover:!bg-neutral-50 text-gray-700'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            viewBox='0 0 48 48'
          >
            <path
              fill='#FFC107'
              d='M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8c-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C12.955 4 4 12.955 4 24s8.955 20 20 20s20-8.955 20-20c0-1.341-.138-2.65-.389-3.917'
            />
            <path
              fill='#FF3D00'
              d='m6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C16.318 4 9.656 8.337 6.306 14.691'
            />
            <path
              fill='#4CAF50'
              d='M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44'
            />
            <path
              fill='#1976D2'
              d='M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002l6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917'
            />
          </svg>
          Continue with google
        </Link>

        <p className='flex items-center justify-between w-full my-5'>
          <span className='w-[40%] border-[.1em] border-gray-900'></span>
          <span className='font-semibold'>Or</span>
          <span className='w-[40%] border-[.1em] border-gray-900'></span>
        </p>

        <form
          className='flex flex-col items-center w-full space-y-8'
          onSubmit={handleSubmit(onSignup)}
        >
          <Input
            type='text'
            id='first_name'
            name='first_name'
            control={control}
            rules={{
              required: 'First name is Required',
            }}
            placeholder='First Name'
          />
          <ErrorInput error={errors?.first_name?.message} />

          <Input
            type='text'
            id='last_name'
            name='last_name'
            control={control}
            rules={{
              required: 'Last name is Required',
            }}
            placeholder='Last Name'
          />
          <ErrorInput error={errors?.last_name?.message} />

          <Input
            type='email'
            name='email'
            control={control}
            rules={{
              required: 'Email is Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            id='email'
            placeholder='Email'
          />
          <ErrorInput error={errors?.email?.message} />

          <Input
            type='number'
            name='phone'
            control={control}
            rules={{
              required: 'Phone Number is Required',
              minLength: {
                value: 11,
                message: 'Phone Number should be 11 numbers',
              },
              maxLength: {
                value: 11,
                message: 'Phone Number must be exactly 11 digits',
              },
            }}
            id='phone'
            placeholder='Phone number'
          />
          <ErrorInput error={errors?.phone?.message} />

          <div className='w-full'>
            <div className='flex justify-between mb-6 border-bottom-[.1rem] border-grey-500 input-box'>
              <Input
                type={visibleText}
                id='password'
                name='password'
                control={control}
                rules={{
                  required: 'Password is Required',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters',
                  },
                  validate: {
                    hasUpperCase: (value: string) =>
                      /[A-Z]/.test(value) ||
                      'Must contain at least one uppercase letter',
                    hasLowerCase: (value: string) =>
                      /[a-z]/.test(value) ||
                      'Must contain at least one lowercase letter',
                    hasNumber: (value: string) =>
                      /\d/.test(value) || 'Must contain at least one number',
                    hasSpecialChar: (value: string) =>
                      /[^A-Za-z0-9]/.test(value) ||
                      'Must contain at least one special character',
                  },
                }}
                className='w-[95%] !outline-none bg-transparent'
                placeholder='Password'
              />
              <span
                className='text-sm text-gray-90'
                onClick={handlePasswordVisibility}
              >
                <i className={`fa-solid ${passwordIcon}`}></i>
              </span>
            </div>
            <ErrorInput error={errors?.password?.message} />

            {password && (
              <div className='mt-2'>
                <div className='flex items-center mb-1'>
                  <span className='mr-2 text-sm font-medium'>
                    Password strength:
                  </span>
                  <div className='flex-1 h-2 bg-gray-200 rounded-full'>
                    <div
                      className={`h-2 rounded-full ${
                        passwordStrength.score < 3
                          ? 'bg-red-500'
                          : passwordStrength.score < 5
                          ? 'bg-yellow-500'
                          : 'bg-green-500'
                      }`}
                      style={{
                        width: `${(passwordStrength.score / 6) * 100}%`,
                      }}
                    ></div>
                  </div>
                </div>
                {passwordStrength.feedback && (
                  <p className='text-xs text-red-500'>
                    {passwordStrength.feedback}
                  </p>
                )}
                <div className='mt-1 text-xs text-gray-500'>
                  <p>Password requirements:</p>
                  <ul className='pl-5 list-disc'>
                    <li
                      className={password.length >= 8 ? 'text-green-500' : ''}
                    >
                      At least 8 characters (12+ recommended)
                    </li>
                    <li
                      className={/[A-Z]/.test(password) ? 'text-green-500' : ''}
                    >
                      At least one uppercase letter
                    </li>
                    <li
                      className={/[a-z]/.test(password) ? 'text-green-500' : ''}
                    >
                      At least one lowercase letter
                    </li>
                    <li className={/\d/.test(password) ? 'text-green-500' : ''}>
                      At least one number
                    </li>
                    <li
                      className={
                        /[^A-Za-z0-9]/.test(password) ? 'text-green-500' : ''
                      }
                    >
                      At least one special character
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className='text-xs text-center lg:w-[70%] my-12'>
            By creating an account, you agree to our {''}
            <Link
              to='/privacy-policy'
              target='_blank'
              className='text-green-600 hover:underline'
            >
              privacy notice,
            </Link>{' '}
            <Link
              to='/terms-and-conditions'
              target='_blank'
              className='text-green-600 hover:underline'
            >
              terms and conditions
            </Link>
            .
          </div>

          {loading ? (
            <button type='submit' className='checkout-btn'>
              <Loading loading={loading} />
            </button>
          ) : (
            <button type='submit' className='checkout-btn'>
              Sign up
            </button>
          )}
        </form>

        <p className='mt-5 text-base'>
          Already have an account?{' '}
          <Link to='/login' className='text-green-600'>
            Log In
          </Link>
        </p>
      </div>

      <div className='w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%]'></div>

      {showAlert.show && (
        <AlertModal
          msg={showAlert.msg}
          click={() => setShowAlert((prev) => ({ ...prev, show: false }))}
        />
      )}
    </section>
  );
};

export default Signup;
