import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import blackLogo from "../../images/logos/black_logo.svg";
import AuthService from "../../config/services/auth.service";
import TokenHelper from "../../config/utils/token.helper";
import Input, { ErrorInput } from "../../components/Input";
import { useForm } from "react-hook-form";
import Loading from "../../components/Loading";
import { onAuthentication } from "../../store/slices/auth.slice";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import CookieHelper from "../../config/utils/cookie.helper";
import { AppKeys } from "../../config/utils/constants";
import { usePostCartMutation } from "../../store/slices/api.slice";

const Login = () => {
  const [visibleText, setVisibleText] = useState("password");
  const [passwordIcon, setPasswordIcon] = useState("fa-eye");
  const [message, setMessage] = useState({ message: "", color: "" });
  const [style, setStyle] = useState("hidden");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();
  const cartCookie = CookieHelper.get(AppKeys.CART)
  const [postCart] = usePostCartMutation()

  // let updatedCatalogue = cartCookie.map((item: any) => {
  //   return {
  //     sku: item.sku
  //   };
  // });
  const handlePasswordVisibility = () => {
    if (passwordIcon === "fa-eye") {
      setPasswordIcon("fa-eye-slash");
      setVisibleText("text");
    } else if (passwordIcon === "fa-eye-slash") {
      setPasswordIcon("fa-eye");
      setVisibleText("password");
    }
  };

  const onLogin = async (values: any) => {
    setLoading(true);
    try {
      const { data: responseData } = await AuthService.login(values);
      if (responseData.status) {
        navigate("/");
        onAuthentication(dispatch, responseData.access)
        const updatedCartCookie = cartCookie.map((item: any) => {
         const res = {sku: item.sku, quantity: item.quantity}
          return res
        })
        const cartBody = {
          catalogue: updatedCartCookie
        }
        if(cartCookie){
          const response = await postCart(cartBody).unwrap();
          if(response.status){
            CookieHelper.remove(AppKeys.CART)
          }
        }
      } else {
        setLoading(false);
        setStyle("flex");
        setMessage({ message: responseData.message, color: "text-red-500" });
      }
    } catch (error: any) {
      setLoading(false);
      setStyle("flex");
      setMessage({ message: error.data.message , color: "text-red-500" });
    }
  };

  return (
    <section className="relative w-full flex justify-between  text-gray-900 py-10">
      <div
        id="toast-default"
        className={`${style} fixed inset-x-[40%] top-0 items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`}
        role="alert"
      >
        <div className={`ms-3 text-sm ${message.color} font-normal`}>
          {message.message}
        </div>
        <button
          type="button"
          className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-default"
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
      <div className="w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%] flex justify-end">
        <Link
          to="/"
          className="w-fit h-fit p-2 border border-neutral-300 rounded-full hover:text-white hover:bg-green-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <path
              fill="currentColor"
              d="M12.269 15.794a.75.75 0 0 1-1.06-.026l-5.002-5.25a.75.75 0 0 1 0-1.035l5.001-5.25a.75.75 0 1 1 1.086 1.034l-4.508 4.734l4.508 4.733a.75.75 0 0 1-.025 1.06"
            />
          </svg>
        </Link>
      </div>
      <div className="w-[80%] md:w-[70%] lg:w-[50%] xl:w-[40%] flex flex-col items-center">
        <Link to="/" className="py-4">
          <img
            src={blackLogo}
            alt="Taikez ecommerce logo"
            width={300}
            height={300}
            className="w-[7rem]"
          />
        </Link>
        <h4 className="text-xl font-bold mt-4 mb-3">Log into your account</h4>
        <Link
          to="/"
          className="checkout-btn !bg-transparent my-6 !py-1.5 !border-2 !border-gray-800 hover:!bg-green-200 text-gray-700 hover:!text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 48 48"
          >
            <path
              fill="#FFC107"
              d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8c-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C12.955 4 4 12.955 4 24s8.955 20 20 20s20-8.955 20-20c0-1.341-.138-2.65-.389-3.917"
            />
            <path
              fill="#FF3D00"
              d="m6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C16.318 4 9.656 8.337 6.306 14.691"
            />
            <path
              fill="#4CAF50"
              d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44"
            />
            <path
              fill="#1976D2"
              d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002l6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917"
            />
          </svg>
          Continue with google
        </Link>
        <p className="w-full flex justify-between items-center my-5">
          <span className="w-[40%] border-[.1em] border-gray-900"></span>
          <span className="font-semibold">Or</span>
          <span className="w-[40%] border-[.1em] border-gray-900"></span>
        </p>
        <form className="w-full flex flex-col items-center space-y-8 mb-8" onSubmit={handleSubmit(onLogin)}>
          <Input
            type="email"
            name="email"
            control={control}
            rules={{
              required: "Email is Required",
            }}
            id="email"
            placeholder="Email"
          />
          <ErrorInput error={errors?.email?.message} />
          {/* <div className="input-box flex justify-between"> */}
          <div className="input-box flex justify-between">
            <Input
              type={visibleText}
              id="password"
              name="password"
              control={control}
              rules={{
                required: "Password is Required",
              }}
              className="w-[95%] !outline-none bg-transparent"
              placeholder="Password"
            />
            <span
              className="text-sm text-gray-90"
              onClick={handlePasswordVisibility}
            >
              <i className={`fa-solid ${passwordIcon}`}></i>
            </span>
          </div>
          <ErrorInput error={errors?.password?.message} />
          <Link
            to="/forgot-password"
            className="w-full !mt-2 text-xs text-neutral-500 font-medium hover:text-green-700 text-right"
          >
            Forgot password?
          </Link>
          <>
            {loading ? (
              <button type="submit" className="checkout-btn">
                <Loading loading={loading} />
              </button>
            ) : (
              <button type="submit" className="checkout-btn" >
                Log in
              </button>
            )}
          </>
          <p className="text-base mt-5">
            <Link to="/signup" className="text-green-600 hover:text-green-700">
              Sign up
            </Link>
          </p>
      </form>
        </div>
      <div className="w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%]"></div>
    </section>
  );
};

export default Login;
