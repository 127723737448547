import axios from 'axios';
import { AppInfo, AppKeys } from '../utils/constants';
import TokenHelper from '../utils/token.helper';
import CookieHelper from '../utils/cookie.helper';

export const LOCALHOST = 'localhost';
export const BETA_BASEURL = '';
export const PROD_BASEURL =
  'https://taikez-prod-fbaca7ca30e6.herokuapp.com/apis/v1';

export const getBaseUrl = (): string => {
  let baseURL = 'https://taikez-prod-fbaca7ca30e6.herokuapp.com/apis/v1';
  return baseURL;
};

const headers = { 'Content-Type': 'application/json' };
const apiInstance = axios.create({ baseURL: getBaseUrl(), headers });

apiInstance.interceptors.request.use(
  (config) => {
    const token = TokenHelper.getToken();
    if (TokenHelper.authenticationValid() && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const token = TokenHelper.getToken();
    if (error.response?.status === 401 && token) {
      CookieHelper.remove(AppKeys.TOKEN);
    }
    return Promise.reject(error.response || error);
  }
);

export default apiInstance;
