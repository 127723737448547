import { useEffect, useState } from 'react';
import ProductGridSection from '../components/ProductGridSection';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import Slider from '../components/Slider';
import FeaturedCat from '../components/FeaturedCat';
import Hero from '../components/Hero';
import RecipeSection from '../components/RecipeSection';
import { RECIPE_DATA } from 'src/data/recipeData';
import { useGetCompositeProductsQuery } from '../store/slices/api.slice';

const Home = () => {
  const {
    data: products,
    isLoading,
    isSuccess,
  } = useGetCompositeProductsQuery('');

  const [showCookieNotice, setShowCookieNotice] = useState(false);

  useEffect(() => {
    const hasSeenNotice = sessionStorage.getItem('hasSeenCookieNotice');

    if (!hasSeenNotice) {
      setShowCookieNotice(true);

      const timer = setTimeout(() => {
        setShowCookieNotice(false);
        sessionStorage.setItem('hasSeenCookieNotice', 'true');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div>
      {showCookieNotice && (
        <div className='fixed z-50 flex items-center justify-between p-4 text-white bg-gray-800 rounded-lg shadow-lg bottom-4 left-4 right-4'>
          <p>
            We use cookies to enhance your browsing experience. By continuing to
            use this site, you agree to our use of cookies.
          </p>
          <button
            onClick={() => setShowCookieNotice(false)}
            className='px-3 py-1 ml-4 text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
            >
              <path
                fill='currentColor'
                d='m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275t.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275z'
              />
            </svg>
          </button>
        </div>
      )}
      <MaxWidthWrapper>
        <Slider />
        <Hero />
        <FeaturedCat title='Featured Categories' />
        <ProductGridSection
          loading={isLoading}
          isSuccess={isSuccess}
          productData={isSuccess ? products.data : []}
        />
      </MaxWidthWrapper>
      <RecipeSection
        title={RECIPE_DATA[0].title}
        link={RECIPE_DATA[0].link}
        bannerSrc={RECIPE_DATA[0].bannerSrc}
        recipes={RECIPE_DATA[0].recipes}
      />
    </div>
  );
};

export default Home;
