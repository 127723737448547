import { Link } from 'react-router-dom';
import CreateBtn from '../../components/Button';
import blackLogo from '../../images/logos/black_logo.svg';

const Auth4 = () => {
  const buttonItem = {
    btnTxt: 'Continue',
    link: '/auth-5',
    svg: '',
  };
  return (
    <section className='authPageWrapper'>
      <div className='w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%] flex justify-end'>
        <Link
          to='/sign-up'
          className='p-2 border rounded-full w-fit h-fit border-neutral-300 hover:text-white hover:bg-green-700'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
          >
            <path
              fill='currentColor'
              d='M12.269 15.794a.75.75 0 0 1-1.06-.026l-5.002-5.25a.75.75 0 0 1 0-1.035l5.001-5.25a.75.75 0 1 1 1.086 1.034l-4.508 4.734l4.508 4.733a.75.75 0 0 1-.025 1.06'
            />
          </svg>
        </Link>
      </div>
      <div className='w-[80%] md:w-[70%] lg:w-[50%] xl:w-[40%] flex flex-col items-center'>
        <Link to='/' className='py-4'>
          <img
            src={blackLogo}
            alt='Taikez ecommerce logo'
            width={300}
            height={300}
            className='w-[7rem]'
          />
        </Link>
        <h4 className='mt-4 mb-3 text-xl font-bold'>Create an account</h4>
        <p className='font-normal text-center lg:text-lg xl:text-xl'>
          Join thousands of customers who purchase their daily food items on{' '}
          <span className='font-medium'>Taikez!</span>
        </p>
        <div className='flex flex-col items-center w-full space-y-8 my-7'>
          <h3 className='w-full text-xl font-semibold text-start'>
            Birth date
          </h3>
          <div className='flex items-center justify-between w-full'>
            <div className='flex items-center justify-between border-0 border-b-2 border-neutral-600'>
              <select
                id='day'
                className='py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer'
              >
                <option defaultValue='Monday'>Day</option>
                <option value='Monday'>Monday</option>
                <option value='Tuesday'>Tuesday</option>
                <option value='Wednesday'>Wednesday</option>
                <option value='Thursday'>Thursday</option>
                <option value='Friday'>Friday</option>
                <option value='Saturday'>Saturday</option>
                <option value='Sunday'>Sunday</option>
              </select>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
              >
                <path
                  fill='currentColor'
                  d='M12 15.25a.74.74 0 0 1-.53-.22l-5-5A.75.75 0 0 1 7.53 9L12 13.44L16.47 9a.75.75 0 0 1 1.06 1l-5 5a.74.74 0 0 1-.53.25'
                />
              </svg>
            </div>
            <div className='flex items-center justify-between border-0 border-b-2 border-neutral-600'>
              <select
                id='day'
                className='py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer'
              >
                <option defaultValue='January'>Month</option>
                <option value='January'>January</option>
                <option value='February'>February</option>
                <option value='March'>March</option>
                <option value='April'>April</option>
                <option value='May'>May</option>
                <option value='June'>June</option>
                <option value='July'>July</option>
                <option value='August'>August</option>
                <option value='September'>September</option>
                <option value='October'>October</option>
                <option value='November'>November</option>
                <option value='December'>December</option>
              </select>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
              >
                <path
                  fill='currentColor'
                  d='M12 15.25a.74.74 0 0 1-.53-.22l-5-5A.75.75 0 0 1 7.53 9L12 13.44L16.47 9a.75.75 0 0 1 1.06 1l-5 5a.74.74 0 0 1-.53.25'
                />
              </svg>
            </div>
            <div className='flex items-center justify-between border-0 border-b-2 border-neutral-600'>
              <select
                id='day'
                className='py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer'
              >
                <option defaultValue='1999'>Year</option>
                <option value='2000'>2000</option>
                <option value='2001'>2001</option>
                <option value='2002'>2002</option>
                <option value='2003'>2003</option>
                <option value='2004'>2004</option>
                <option value='2005'>2005</option>
                <option value='2006'>2006</option>
              </select>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
              >
                <path
                  fill='currentColor'
                  d='M12 15.25a.74.74 0 0 1-.53-.22l-5-5A.75.75 0 0 1 7.53 9L12 13.44L16.47 9a.75.75 0 0 1 1.06 1l-5 5a.74.74 0 0 1-.53.25'
                />
              </svg>
            </div>
          </div>
          <select
            id='countries'
            className='bg-transparent border-2 border-neutral-600 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 w-full py-2.5 px-3'
          >
            <option defaultValue='Male'>Gender</option>
            <option value='male'>Male</option>
            <option value='female'>Female</option>
            <option value='nonBinary'>Non binary</option>
          </select>
        </div>
        <div className='w-[50%]'>
          <CreateBtn item={buttonItem} />
        </div>
        <div className='flex items-center mt-8'>
          <input
            id='link-radio'
            type='radio'
            value=''
            className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 focus:ring-2'
          />
          <label
            htmlFor='link-radio'
            className='text-xs font-medium text-gray-900 ms-2'
          >
            I have read and consented to the{' '}
            <Link
              to='/terms-and-conditions'
              target='_blank'
              className='text-green-600 hover:underline'
            >
              Terms and Conditions
            </Link>
            .
          </label>
        </div>
      </div>
      <div className='w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%] '></div>
    </section>
  );
};

export default Auth4;
