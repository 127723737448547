export const AppInfo = {
  APP_NAME: 'Taikez eCommerce App',
  ORG_NAME: 'Taikez Services',
  APP_VERSION: 'v1',
  BASE_URL: 'https://taikez-prod-fbaca7ca30e6.herokuapp.com/apis/v1',
  BASE_STAGING_URL: 'https://taikez-prod-fbaca7ca30e6.herokuapp.com/apis/v1'
}

export const AppKeys = {
  TOKEN:
    process.env.REACT_APP_TOKEN ||
    'T4ik3zzeC0MM3rC3-0987Tt1V13wMT4-987to89nC0D30f1529CUlpCyvNmm8SCR36Gp8a4r5hyP41k3KAxfC5JU',
  GOOGLE_AUTH_KEY:
    process.env.REACT_APP_GOOGLE_AUTH_KEY ||
    '846567812607-qr3ivqac95pvkcjebf3744q8tq2banre.apps.googleusercontent.com',
  COOKIE_EXPIRY_PERIOD: 1,
  CART: 'CART',
};

export const Loading = {
  ERROR: 'ERROR',
  FAILED: 'FAILED',
  CREATED: 'CREATED',
  SUCCESS: 'SUCCESS',
  FETCHING: 'FETCHING',
  NO_CONTENT: 204,
} as const;

export type LoadingState = (typeof Loading)[keyof typeof Loading];
